<template>

  <form name=CoinwikiForm action="https://www.coinwiki.shop/newmatching/" target="" method="post">
    <input type=hidden name="groupid" :value="'stca1'">
    <input type=hidden name="groupkey" :value="'z0NEQAzwd3xXYvd7'">
    <input type=hidden name="groupuserid" :value="userData.memId">
    <input type=hidden name="groupusername" :value="userData.memName">
    <input type=hidden name="groupstr1">
    <input type=hidden name="groupstr2">
    <input type=hidden name="returntrueurl">
    <input type=hidden name="returnfalseurl">
  </form>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'CoinWiki',
  data () {
    return {

    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  mounted () {
    document.CoinwikiForm.submit()
  }
}
</script>
<style scoped>

iframe {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100%;
}
</style>
